<template>
  <b-card>
    <div class="row justify-content-between">
      <div class="d-flex align-items-center justify-content-start mb-1 col-md-12">
        <b-button
          variant="adn"
          data-action-type="new"
          class="mr-1"
          @click="
            (v) => {
              openModal();
            }
          "
        >
          <feather-icon
            icon="UploadIcon"
            class="mr-25"
          />
          {{ $t('uploadItemsFromExcelFile') }}
        </b-button>

        <b-button
          variant="outline-adn"
          class="mr-1"
          data-action-type="new"
          @click="
            (v) => {
              downloadExcelSheet();
            }
          "
        >
          <feather-icon
            icon="DownloadIcon"
            class="mr-25"
          />
          {{ $t('downloadExcelSheet') }}
        </b-button>
        <b-button
          variant="adn"
          @click="openSettingModal"
        >
          <feather-icon
            icon="SettingsIcon"
            class="mr-25"
          />
          {{ $t('updateItemsPrice') }}
        </b-button>
      </div>
    </div>

    <b-row>
      <b-button
        variant="adn"
        class="btn-filter"
        @click="toggleFilters"
      >
        {{ showFilters ? $t('hideFilter') : $t('showFilter') }}
        <feather-icon
          icon="FilterIcon"
          class="ml-50"
        />
      </b-button>
    </b-row>

    <b-row
      v-if="showFilters"
      class="filter-section"
    >
      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          :value.sync="filter.CategoryId"
          :dir="isRight ? 'rtl' : 'ltr'"
          label-text="category"
          field="select"
          :options="categories"
          :label="isRight ? 'arabicName' : 'englishName'"
          @change="() => refreshItems()"
          @input="() => refreshItems()"
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          id="barcode"
          v-silent-search
          :value.sync="filter.Barcode"
          label-text="barcode"
          name="barcode"
          @keydown.enter="
            (v) => {
              refreshItems();
            }
          "
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          id="arabicName"
          v-silent-search
          :value.sync="filter.ArabicNamePart"
          label-text="arabicName"
          name="arabicName"
          @keydown.enter="
            (v) => {
              refreshItems();
            }
          "
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          id="englishName"
          v-silent-search
          :value.sync="filter.EnglishNamePart"
          label-text="englishName"
          name="englishName"
          @keydown.enter="
            (v) => {
              refreshItems();
            }
          "
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          :value.sync="filter.storeId"
          :dir="isRight ? 'rtl' : 'ltr'"
          label-text="store"
          field="select"
          name="store"
          :options="storesData"
          label="arabicName"
          @input="() => {
            $refs['items-table'].refreshTable();
          }"
          @change="() => {
            $refs['items-table'].refreshTable();
          }"
        />
      </b-col>

      <b-col
        sm="6"
        xs="12"
      >
        <label
          class="d-block"
          style="margin-bottom: 7px; font-size: 14px;"
        >
          {{ this.$t('itemBalance') }}
        </label>
        <b-form-radio-group
          v-model="filter.itemBalanceNature"
          button-variant="outline-warning"
          :options="itemsTypesOptions"
          buttons
          name="radios-btn-default"
          @change="() => {
            refreshItems();
          }"
        />
      </b-col>
    </b-row>

    <div class="my-2">
      <b-row>
        <b-col
          md="12"
          sm="12"
          class="text-right"
        >
          <b-button
            variant="primary"
            data-action-type="new"
            @click="
              (v) => {
                this.$router.push({ name: 'add-item' });
              }
            "
          >
            {{ $t('new') }}
          </b-button>
          <vue-excel-xlsx
            :data="itemsArray"
            :columns="tableColumns"
            :filename="this.$route.name"
            :sheetname="'xlsxSheet'"
            class="btn btn-relief-success ml-1 p-0 ptn-sm"
          >
            <img
              src="@/assets/images/icons/xls.jpg"
              alt=""
              style="height: 39px;width: auto;"
            >
          </vue-excel-xlsx>
          <b-button
            variant="relief-danger"
            class="ml-1 p-0 ptn-sm"
            @click="printDocument"
          >
            <img
              src="@/assets/images/icons/pdf.jpg"
              alt=""
              style="height: 39px;width: auto;"
            >
          </b-button>
        </b-col>
      </b-row>
    </div>
    <g-table
      ref="items-table"
      :items="itemsSearchProvider"
      :columns="tableColumns"
      :is-busy="isTableBusy"
      :noAction="true"
      perPage="25"
      :totalRows="totalRows"
      :createButton="{ visiable: false }"
      :excelButton="{ visiable: false }"
      :pdfButton="{ visiable: false }"
      :searchInput="{ visiable: false }"
    >
      <template
        v-if="hideActions === false"
        #actions="{ item }"
      >
        <div class="text-nowrap">
          <b-button
            v-b-tooltip.hover.top="$t('edit')"
            v-permission=" 'editItems'"
            data-action-type="edit"
            variant="flat-secondary"
            class="btn-icon"
            size="sm"
            @click="edit(item)"
          >
            <feather-icon
              :id="`item-row-${item.id}-preview-icon`"
              icon="EditIcon"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('printBarCodeReport')"
            v-permission=" 'editItems'"
            data-action-type="edit"
            variant="flat-success"
            class="btn-icon"
            size="sm"
            @click="printBarCode(item)"
          >
            <feather-icon
              :id="`item-row-${item.id}-preview-icon`"
              icon="ClipboardIcon"
              stroke="green"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('delete')"
            v-permission=" 'deleteItems'"
            data-action-type="delete"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="remove(item)"
          >
            <feather-icon
              :id="`item-row-${item.id}-delete-icon`"
              icon="TrashIcon"
              stroke="red"
              class="danger"
            />
          </b-button>
        </div>
      </template>
    </g-table>
    <b-modal
      ref="edit-modal"
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      centered
      size="lg"
      hide-footer
      :title="$t('exportNewItems')"
    >
      <g-form @submit="uploadItems">
        <b-row>
          <b-col cols="12">
            <div>
              <input
                type="file"
                class="excel-upload-input"
                accept=".xlsx, .xls"
                @change="handleFileUpload( $event )"
              >
              <b-alert
                v-if="file"
                show
                fade
                class="mt-2 text-center"
                variant="success"
              >
                <div class="alert-body">
                  <span>{{ $t('importSuccsefly') }} {{ file.name }}</span>
                </div>
              </b-alert>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <hr>
          </b-col>
          <b-col
            cols="12"
            class="d-flex justify-content-end"
          >
            <b-button
              v-permission="$route.meta.permission"
              class="mx-1"
              type="submit"
              variant="primary"
              data-action-type="save"
            >
              {{ $t('save') }}
            </b-button>
            <b-button
              class="mx-1"
              variant="secondary"
              @click="closeModal"
            >
              {{ $t('Close') }}
            </b-button>
          </b-col>
        </b-row>
      </g-form>
    </b-modal>
    <b-modal
      ref="setting-modal"
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      centered
      size="lg"
      hide-footer
      :title="$t('updateItemsPrice')"
    >
      <g-form @submit="updateItemsPrice">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <g-field
                :value.sync="selectedItem.categoryId"
                :dir="isRight ? 'rtl' : 'ltr'"
                label-text="category"
                field="select"
                :options="categories"
                rules="required"
                :label="isRight ? 'arabicName' : 'englishName'"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="8">
            <b-form-radio-group
              v-model="selectedItem.updateType "
              button-variant="outline-primary"
              :options="optionsRadio"
              rules="required"
              buttons
              name="radios-btn-type"
            />
          </b-col>
        </b-row>
        <b-row class="pt-2">
          <b-col md="8">
            <b-form-radio-group
              v-model="selectedItem.valueType "
              button-variant="outline-primary"
              :options="optionsValueRadio"
              rules="required"
              buttons
              name="radios-btn-value"
            />
          </b-col>
        </b-row>
        <b-row class="pt-2">
          <b-col md="6">
            <g-field
              :value.sync="selectedItem.value"
              type="number"
              :rules="selectedItem.valueType === 'Precentage' ? `min_value:0|required` : `min_value:0|required`"
              :label-text="selectedItem.valueType === 'Precentage' ? 'Precentage' : 'value'"
              name="value"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="d-flex justify-content-end mt-1"
          >
            <b-button
              v-permission="$route.meta.permission"
              class="mr-1"
              type="submit"
              variant="primary"
              data-action-type="save"
            >
              {{ $t('save') }}
            </b-button>
            <b-button
              variant="secondary"
              @click="closeSettingModal"
            >
              {{ $t('Close') }}
            </b-button>
          </b-col>
        </b-row>
      </g-form>
    </b-modal>
  </b-card>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.3/jspdf.min.js"></script>
<script src="@/pages/Shared/html2canvas.js"></script>
<script>
import GTable from '@/pages/Shared/Table.vue';
import reportMixin from '@/mixin/reportMixin';
import saveAs from 'file-saver';
import { itemsTypes } from '@/libs/acl/Lookups';
// import UploadExcelComponent from '@/libs/UploadExcel/index.vue';

export default {
  components: {
    GTable,
    // UploadExcelComponent
  },
  mixins: [
    reportMixin,
  ],
  data() {
    return {
      fileFromBE: '',
      file: '',
      searchQuery: '',
      currentPage: 1,
      perPage: 25,
      itemsTypes,
      filter: {
        DealerId: '',
        FromDealer: '',
        ToDealer: '',
        FromDate: '',
        ToDate: '',
        OrderClause: '',
        itemBalanceNature: '',
        CategoryId: ''
      },
      isTableBusy: false,
      totalRows: 0,
      stores: [],
      pathName: this.$route.name,
      itemsArray: [],
      hideActions: false,
      storesData: [],
      categories: [],
      selectedItem: {
        updateType: 'increase',
        valueType: 'Precentage'
      },
      showFilters: false,
    };
  },
  computed: {
    itemsTypesOptions() {
      return itemsTypes.map((item) => {
        return {
          text: this.isRight ? item.arabicName : item.englishName,
          value: item.id,
        }
      });
    },
    tableColumns() {
      return [
        {
        key: 'mainUnitBarcode',
        field: 'mainUnitBarcode',
        label: this.$t('barcode'),
        sortable: true
        },
        {
          key: 'arabicName',
          field: 'arabicName',
          label: this.$t('itemName'),
          sortable: true,
        },
        {
          key: 'salesPrice',
          field: 'salesPrice',
          label: this.$t('itemPrice'),
          sortable: false,
          type: 'number'
        },
        {
          key: 'categoryArabicName',
          field: 'categoryArabicName',
          label: this.$t('categoryName'),
          sortable: true,
        },
        {
          key: 'totalCurrentBalance',
          field: 'totalCurrentBalance',
          label: this.$t('itemBalance'),
          sortable: false,
          type: 'number'
        },
        { key: 'actions' },
      ];
    },
    optionsRadio() {
      return [
        { text: this.$t('increasePrice'), value: 'increase' },
        { text: this.$t('decreasePrice'), value: 'decrease' },
      ];
    },
    optionsValueRadio() {
      return [
        { text: this.$t('Precentage'), value: 'Precentage' },
        { text: this.$t('aValue'), value: 'value' },
      ];
    },
  },
  mounted() {
    this.loadData();
    this.getStores();
  },
  methods: {
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
    printBarCode(data) {
      const printedItem = {
        id: data.id,
        unitId: data.mainUnitId,
        storeId: data.stores[0].storeIde
      }
       this.printReport('ItemBarCodeReport-ar', printedItem);
    },
    loadData() {
      this.get({ url: 'Aggregates/Items' }).then((data) => {
        // this.storesData = data.stores;
        if (this.currentBranch.stores && this.currentBranch.stores.length > 0) {
        const branchStores = this.currentBranch.stores.map(
          (item) => item.storeId
        );
        this.storesData = data.stores.filter(
          (item) => branchStores.indexOf(item.id) > -1
        );
      } else {
        this.storesData = [];
      }
        this.categories = data.itemCategories;
      });
    },
    hide() {
      this.hideActions = true
    },
    pdfExport(name) {
      html2canvas(document.getElementById('mainTable')).then(function(canvas){
      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF('p', 'mm');
      var position = 0;

      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
          doc.save(`${name}.pdf`);
      });
    },
    printDocument() {
      this.hide()
        setTimeout(() => {this.pdfExport(this.$route.name)
        this.hideActions = false
        }, 500);
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
      if (!this.isExcel(this.file)) {
        this.file = '';
        this.doneAlert({ text: this.$t('Only supports upload .xlsx, .xls, .csv suffix files'), type: 'error' });
        this.file = '';
        return false
      }
    },
    isExcel(file) {
      return /\.(xlsx|xls|csv)$/.test(file.name)
    },
    uploadItems() {
    const formData = new FormData();
    formData.append('file', this.file);
    this.create({ url: 'items/data-migration', data: formData })
      .then(() => {
        this.closeModal();
        this.doneAlert({ text: this.$t('savedSuccessfully') });
        this.$refs['items-table'].refreshTable();
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    refreshItems() {
      this.$refs['items-table'].refreshTable();
    },
    getStores() {
      this.get({ url: 'Stores' }).then((data) => {
        if (this.currentBranch.stores && this.currentBranch.stores.length > 0) {
        const branchStores = this.currentBranch.stores.map(
          (item) => item.storeId
        );
        this.stores = data.filter(
          (item) => branchStores.indexOf(item.id) > -1
        );
      } else {
        this.stores = [];
      }
      });
    },
    itemsSearchProvider(ctx, callback) {
      const {
        currentPage, perPage, sortBy, sortDesc
      } = ctx;

      // this.totalRows = 0;
      this.filter.OrderClause = this.orderQuery('Id', true);
      let params = `?pageNumber=${currentPage}&pageSize=${perPage}&`;

      params += this.getFilterObj(this.filter);
      this.isTableBusy = true;
      this.get({ url: `Items${params}` })
        .then(({ data, totalCount }) => {
          this.isTableBusy = false;
          this.totalRows = totalCount === 0 ? 1 : totalCount;
          data.filter((item) => {
               item.totalCurrentBalance = 0;
            if (item.stores.length > 0) {
             item.stores.filter((store) => {
             item.totalCurrentBalance += store.currentBalance
             return true;
             });
            }
            return true;
          })
          callback(data);
          this.itemsArray = data;
        })
        .catch(() => {
          this.isTableBusy = false;
          callback([]);
        });
      // Must return null or undefined to signal b-table that callback is being used
      return null;
    },
    remove(item) {
      this.confirmAction(
        {
          text: this.$t('Are you sure you want to delete?'),
        },
        () => {
          this.delete({
            url: 'Items',
            id: item.id,
          }).then(() => {
            this.doneAlert({ text: this.$t('deletedSuccessfully') });
            this.refreshItems();
          });
        }
      );
    },
    edit(item) {
      this.$router.push({
        name: 'edit-item',
        params: { id: item.id },
      });
    },
    updateItemsPrice() {
      this.create({ url: 'items/update-item-price', data: this.selectedItem })
      .then(() => {
        this.closeSettingModal();
        this.doneAlert({ text: this.$t('savedSuccessfully') });
        this.$refs['items-table'].refreshTable();
      });
    },
    closeSettingModal() {
      this.$refs['setting-modal'].hide();
    },
    openSettingModal() {
      this.$refs['setting-modal'].show();
    },
    closeModal() {
      this.$refs['edit-modal'].hide();
      this.file.name = '';
    },
    openModal() {
      this.$refs['edit-modal'].show();
    },
    downloadExcelSheet() {
      this.get({ url: 'Items/data-migration', responseType: 'arraybuffer' }).then((data) => {
        saveAs(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), 'items.xlsx');
      });
    }
  },
};
</script>

<style>
.filter-section {
  background-color: #f9f9f9;
  margin: 15px 0;
  padding: 25px 0 5px;
  border-radius: 7px;
  border-top-left-radius: 0;
  border: 2px solid #ad8884;
}

.btn-filter {
  margin-left: 14px;
  width: 157px;
  margin-bottom: -16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
